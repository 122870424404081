import React from "react";
import PageWrapper from "../components/PageWrapper";
import ACthree from '../sections/landing/aceleven';
import Pro from '../sections/nose/nosesteps.js';
import { JsonLd } from '../components/JsonLd';
import Teargallery from "../sections/tear/teargallery.js";
import Contenttear from "../sections/tear/tearcontent.js";
import TearFaq from "../sections/tear/tearfaq.js";
import Head from "../components/head";
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/tear-hero-pop.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import Testimonial from "../sections/index/indexreviews.js";

const Tear = () => {
  return (
    <>
     <Head title="Tear Trough Filler | Dermal Filler for Dark Circles"  description="Tear trough filler is dermal filler treatment designed to level out the area below the eyes and diffuse dark shadows, restoring volume and minimising darkness." keywords="Nose jobs, Rhinoplasty, Liquid nose job, Tear Trough, Under Eye Filler, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, Tear Trough Filler, Pain free Tear Trough"/>

     
<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
<PageWrapper headerDark>
<HeroComponent
    itext='<span style="color: #1a1a1a;">Tear </span> <span style="color: #00aec7;">Trough</span>'
    secondText="Brighten, refresh and revitalise the look of your under-eyes"
    videoAlt="Tear Trough"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Tear Trough"
  resultstext="12 - 18 months"
  resultsalt="Tear Trough results"
  costdata="£450"
  costalt="Tear Trough Cost"
  timedata="10 Minutes"
  timealt="Tear Trough Duration Time"
  workdata="Immediately"
  workalt="Tear Trough downtime work"
  paindata="None / Mild"
  painalt="Tear Trough Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Teargallery />
<Award />
<Testimonial />
<Contenttear />
<Pro />
<ACthree />
<TearFaq />        
<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default Tear;
